import picture1 from "./picture/a7c3e85dd5517b4def87f49a8c6081d.jpg";

function EndPage () {

    return(
        <>
            <div id="phone" style={{backgroundColor:"RoyalBlue"}}>
                <div style={{marginLeft:"7.5%",marginBottom:"-2.6%", display:"flex", flexDirection:"row"}}>
                    <div style={{display:"flex", flexDirection:"column", margin:" 0px 5%"}}>
                        <b style={{ fontSize:window.innerWidth*0.021, color:"white", fontFamily:"SimSun", marginLeft:"0.5%", fontWeight:"bolder"}}>中科心感（南京）医疗电子研究院有限公司</b>
                        <p style={{ fontSize:window.innerWidth*0.0158, color:"white", fontFamily:"SimSun", marginLeft:"0.5%", fontWeight:"bold", marginTop:"1%"}}>Zhongke Xinggan (Nanjing) Medical Technology Co., Ltd</p>
                        <p style={{ fontSize:window.innerWidth*0.0158, color:"white", fontFamily:"SimSun", marginLeft:"0.5%", fontWeight:"bold", marginTop:"0.26%"}}>地址:南京市浦口区江浦街道浦滨路320号科创总部大厦C座15F</p>
                        <p style={{ fontSize:window.innerWidth*0.0158, color:"white", fontFamily:"SimSun", marginLeft:"0.5%", fontWeight:"bold", marginTop:"0.26%"}}>联系我们: +025-5816 2151</p>
                        <p style={{color:"white",fontSize:window.innerWidth*0.0079, margin:"-0.8% -0.3% -0.79% 96.3%", fontWeight:"bolder"}}> &gt; &gt;</p>
                        <hr style={{width:window.innerWidth*0.47, height:"1px", backgroundColor:"white", marginTop:"1%", marginLeft:"0.5%"}}/>
                    </div>
                    <div style={{margin:"0px 0.5% 9.3% 5.3%", width:window.innerWidth*0.111, display:"flex", flexDirection:"column"}}>
                        <img style={{height:window.innerWidth*0.081, width:window.innerWidth*0.081, marginLeft:"1.58%", marginBottom:"1.3%"}} src={picture1} alt=""/>
                        <p style={{ fontSize:window.innerWidth*0.0158, color:"white", fontFamily:"SimSun", marginLeft:"0.5%", fontWeight:"bold", marginTop:"0.26%", marginRight:"1%"}}>服务咨询请扫码官方微信公众号</p>
                    </div>
                </div>

            </div>



        </>
    )
}

export default EndPage
