// import monitor from "./picture/monitor.png"
import monitor from "./picture/稿定设计-5.png"
import background from "./picture/稿定设计-6.png"
import {Component} from "react";

class Monitor extends Component {
    render() {
        const backImg = {
            backgroundImage:`url(${background})`,
            backgroundSize:"100% 100%",
            // height:'10vh',
            // scrollMarginTop:"140px",
            display:"flex",
            marginTop:window.innerWidth*0.0894,
            // fontSize:'50px',
        }
        return (
            <>
                <div id="monitor_introduce" style={backImg}>
                    <div style={{height: "26%", marginLeft:"15%", marginTop:"100px"}}>
                        <b style={{fontSize:window.innerWidth*0.052, opacity:"0.1"}}>非接触式</b>
                        <p style={{fontSize:window.innerWidth*0.036, fontWeight:"bold", marginTop:"0px", marginBottom:"30px"}}>动态心率呼吸监测仪</p>
                        <b style={{backgroundColor:"RoyalBlue  ", color:"white", fontSize:window.innerWidth*0.021, marginTop:"0px", paddingLeft:"20px", paddingRight:"20px"}}>- 您枕下的无扰健康管家 -</b>
                        <div style={{display:"flex"}}>
                            <div>
                                <p style={{fontSize:window.innerWidth*0.016, marginBottom:"10px"}}>精准睡眠监测</p>
                                <p style={{fontSize:window.innerWidth*0.016, marginTop:"10px"}}>心率变异监测</p>
                            </div>
                            <div style={{marginLeft:"30px"}}>
                                <p style={{fontSize:window.innerWidth*0.016, marginBottom:"10px"}}>呼吸暂停监测</p>
                                <p style={{fontSize:window.innerWidth*0.016, marginTop:"10px"}}>慢性病程管理</p>
                            </div>

                        </div>

                    </div>
                    {/*position:"absolute", right:"0px", bottom:"142px"*/}
                    <div style={{ marginRight:"-15%"}}>
                        <img style={{height:window.innerWidth*0.35, width:window.innerWidth*0.5 }} src={monitor} alt=""/>
                        {/*style={{height:"50", width:"50"}}*/}
                    </div>

                </div>

            </>
        )
    }

}

// function Monitor() {
//     return (
//         <>
//             <div style={{display:"flex", marginTop:"140px", backgroundImage:`url(${background})`}}>
//                 <div style={{height: "500px", marginLeft:"300px"}}>
//                     <b style={{fontSize:"100px", opacity:"0.1"}}>非接触式</b>
//                     <p style={{fontSize:"70px", fontWeight:"bold", marginTop:"0px", marginBottom:"50px"}}>动态心率呼吸监测仪</p>
//                     <b>- 您枕下的无扰健康管家 -</b>
//                     <div style={{display:"flex"}}>
//                         <div>
//                             <p>精准睡眠监测</p>
//                             <p>心率变异监测</p>
//                         </div>
//                         <div>
//                             <p>呼吸暂停监测</p>
//                             <p>慢性病程管理</p>
//                         </div>
//
//                     </div>
//
//                 </div>
//                 <div style={{position:"absolute", right:"0px", bottom:"142px"}}>
//                     {/*<img  src={monitor} alt=""/>*/}
//                     {/*style={{height:"50", width:"50"}}*/}
//                 </div>
//
//             </div>
//
//             {/*<div style={{ type:"text/css", height: "40px", margiLeft:"510px", display:"flex", backgroundColor:"DodgerBlue"}}>*/}
//             {/*    <p style={{type:"text", marginLeft:"420px", textAlign:"center", marginTop:"10px", fontSize:"20px", color:"white"}}>首页</p>*/}
//             {/*    <p style={{type:"text", marginLeft:"50px", textAlign:"center", marginTop:"10px", fontSize:"20px", color:"white"}}>镇店之宝</p>*/}
//             {/*    <p style={{type:"text", marginLeft:"50px", textAlign:"center", marginTop:"10px", fontSize:"20px", color:"white"}}>公司介绍</p>*/}
//             {/*    <p style={{type:"text", marginLeft:"50px",textAlign:"center", marginTop:"10px", fontSize:"20px", color:"white"}}>产品介绍</p>*/}
//             {/*    <p style={{type:"text", marginLeft:"50px",textAlign:"center", marginTop:"10px", fontSize:"20px", color:"white"}}>联系方式</p>*/}
//             {/*</div>*/}
//         </>
//     )
// }

export default Monitor;



