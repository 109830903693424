import background1 from "./picture/background1.png"
import picture1 from "./picture/mon2.png"
import picture from "./picture/微信图片_20230621171615.png"
import {Component} from "react";
import {Col, Row} from "antd";

class ProductIntroduce extends Component {
    render() {
        const backimg = {
            backgroundImage:`url(${background1})`,
            backgroundSize:"100% 100%",
            // height:'10vh',
            // // scrollMarginTop:"140px",
            // marginTop:'50px',
            // fontSize:'50px',
        }
        return (
            <>
                <div id="product_introduce" style={backimg}>
                    <div style={{padding:"2.6% 20px 0px 1%"}}>
                        <div style={{textAlign:"center", fontSize:window.innerWidth*0.021}}>
                            <b>产品介绍</b>
                        </div>
                        <hr style={{width:window.innerWidth*0.473, height:"1px", backgroundColor:"black", marginTop:window.innerWidth*0.01}}/>
                        <div style={{display:"flex"}}>
                          <Row>
                            <Col span={12}>
                              <div style={{paddingLeft:window.innerWidth*0.047, marginRight:window.innerWidth*-0.274}}>
                                <img style={{height:window.innerWidth*0.368, width:window.innerWidth*0.394, }} src={picture1} alt=""/>
                              </div>
                            </Col>
                            <Col span={12}>
                              <div style={{height: window.innerWidth*0.26, marginLeft:window.innerWidth*0.05, marginTop:window.innerWidth*0.07}}>
                                <p style={{fontSize:window.innerWidth*0.013, backgroundColor:"orange",color:"white", marginRight:window.innerWidth*0.29, paddingLeft:window.innerWidth*0.0068}}>人工智能无感式监测</p>
                                <p style={{fontSize:window.innerWidth*0.013,fontFamily:"SimSun", fontWeight:"bold", marginTop:"0px", marginBottom:window.innerWidth*0.016, marginRight:window.innerWidth*0.2}}>产品属圆盘片状外形，简单的一次性操作就可以通过心率等生物数据测试健康隐患及当下的身体健康数据</p>
                                <div>
                                  <div>
                                    <p style={{fontSize:window.innerWidth*0.013, backgroundColor:"orange",color:"white", marginRight:window.innerWidth*0.31, paddingLeft:"13px"}}>主要数据监测</p>
                                    <p style={{fontSize:window.innerWidth*0.013,fontFamily:"SimSun", fontWeight:"bold", marginTop:"0px", marginBottom:"30px", marginRight:window.innerWidth*0.26}}>睡眠、心率、呼吸、HRV</p>
                                  </div>
                                  <div>
                                    <p style={{fontSize:window.innerWidth*0.013, backgroundColor:"orange",color:"white", marginLeft:window.innerWidth*0.0001,marginRight:window.innerWidth*0.39, paddingLeft:window.innerWidth*0.0068}}>特性</p>
                                    <p style={{fontSize:window.innerWidth*0.013,fontFamily:"SimSun", fontWeight:"bold", marginTop:"0px", marginBottom:"30px", marginRight:window.innerWidth*0.20}}>无感式非接触监测、智能、便捷、易操作</p>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>



                        </div>
                    </div>


                </div>

            </>
        )
    }

}



export default ProductIntroduce;



