import picture1 from "./picture/icon/6a8075731acf6db24559140aa46bb3c.png"
import picture2 from "./picture/icon/58324f6928455520d9deacda5b77a4b.png"
import picture3 from "./picture/icon/肺.png"
import picture4 from "./picture/icon/睡眠 (1).png"
import picture5 from "./picture/icon/i-睡眠监测器坐.png"
import picture6 from "./picture/icon/床垫呼吸暂停报警.png"
import picture7 from "./picture/icon/HRV(心率变异性).png"
import picture8 from "./picture/icon/免疫力.png"
import picture9 from "./picture/icon/感染.png"
import picture10 from "./picture/icon/心率异常提醒.png"
import picture11 from "./picture/icon/菜单-冠心病.png"
import picture12 from "./picture/icon/糖尿病.png"
import picture13 from "./picture/icon/高血压.png"
import picture14 from "./picture/icon/焦虑.png"
import picture15 from "./picture/icon/心理咨询.png"
import picture16 from "./picture/icon/猝死.png"

function LndicatorCollection () {
    return(
        <>
            <div>
                <div style={{position:"relative", display:"inlineBlock"}}>
                    <img style={{width:"100%", marginTop:"-11.6%"}} src={picture1} alt=""/>
                    <div style={{position:"absolute", top:"0", left:"47.3%", color:"red", padding:"0.5%", marginTop:window.innerWidth*0.16, marginLeft:"2%", display:"flex", flexDirection:"row"}}>
                        <b style={{fontSize:window.innerWidth*0.042}}>
                            14+
                        </b>
                        <p style={{color:"black", fontSize:window.innerWidth*0.021, marginLeft:window.innerWidth*0.005}}>
                            健康指标采集与分析
                        </p>
                        <div style={{display:"flex", flexDirection:"column", marginTop:"29.1%", marginLeft:"-62.1%"}}>
                            <p style={{color:"white", fontSize:window.innerWidth*0.021, marginLeft:"0.6%", marginBottom:"-1%", marginRight:"-2.8%"}}>
                                夜间无感睡眠状态监测
                            </p>
                            <p style={{color:"white", fontSize:window.innerWidth*0.021, marginLeft:"0.5%", marginBottom:"-1%"}}>
                                全方面健康指标分析
                            </p>
                            <p style={{color:"white", fontSize:window.innerWidth*0.021, marginLeft:"0.5%"}}>
                                疾病风险早知道
                            </p>
                        </div>
                    </div>
                    <div style={{display:"flex", flexDirection:"row",textAlign:"center", justifyContent:"space-between", padding:"0px 0.5% 0px 0px", margin:"0.5% 16% 0px 16%"}}>
                        <div>
                            {/*<img style={{height:"113px", width:"110px"}} src={picture2} alt=""/>*/}
                            <img style={{height:window.innerWidth*0.08, width:window.innerWidth*0.07}} src={picture2} alt=""/>
                            <p style={{fontWeight:"bold"}}>心率</p>
                        </div>
                        <div>
                            <img style={{height:window.innerWidth*0.08, width:window.innerWidth*0.07}} src={picture3} alt=""/>
                            <p style={{fontWeight:"bold"}}>呼吸</p>
                        </div>
                        <div>
                            <img style={{height:window.innerWidth*0.08, width:window.innerWidth*0.07}} src={picture4} alt=""/>
                            <p style={{fontWeight:"bold"}}>睡眠</p>
                        </div>
                        <div>
                            <img style={{height:window.innerWidth*0.08, width:window.innerWidth*0.07}} src={picture5} alt=""/>
                            <p style={{fontWeight:"bold"}}>体动</p>
                        </div>
                        <div>
                            <img style={{height:window.innerWidth*0.08, width:window.innerWidth*0.07}} src={picture6} alt=""/>
                            <p style={{fontWeight:"bold"}}>呼吸暂停</p>
                        </div>
                        <div>
                            <img style={{height:window.innerWidth*0.08, width:window.innerWidth*0.07}} src={picture7} alt=""/>
                            <p style={{fontWeight:"bold"}}>心率变异性</p>
                        </div>
                        <div>
                            <img style={{height:window.innerWidth*0.08, width:window.innerWidth*0.07}} src={picture8} alt=""/>
                            <p style={{fontWeight:"bold"}}>免疫力</p>
                        </div>
                        <div>
                            <img style={{height:window.innerWidth*0.08, width:window.innerWidth*0.07}} src={picture9} alt=""/>
                            <p style={{fontWeight:"bold"}}>感染风险</p>
                        </div>
                    </div>
                    <div style={{display:"flex",textAlign:"center", flexDirection:"row", justifyContent:"space-between", padding:"0px 0.5% 0px 0px", margin:"0px 21% 0px 21%"}}>
                        <div>
                            <img style={{height:window.innerWidth*0.08, width:window.innerWidth*0.07}} src={picture10} alt=""/>
                            <p style={{fontWeight:"bold"}}>心率失常</p>
                        </div>
                        <div>
                            <img style={{height:window.innerWidth*0.08, width:window.innerWidth*0.07}} src={picture11} alt=""/>
                            <p style={{fontWeight:"bold"}}>冠心病</p>
                        </div>
                        <div>
                            <img style={{height:window.innerWidth*0.08, width:window.innerWidth*0.07}} src={picture12} alt=""/>
                            <p style={{fontWeight:"bold"}}>糖尿病</p>
                        </div>
                        <div>
                            <img style={{height:window.innerWidth*0.08, width:window.innerWidth*0.07}} src={picture13} alt=""/>
                            <p style={{fontWeight:"bold"}}>高血压</p>

                        </div>
                        <div>
                            <img style={{height:window.innerWidth*0.08, width:window.innerWidth*0.07}} src={picture14} alt=""/>
                            <p style={{fontWeight:"bold"}}>焦虑水平</p>
                        </div>
                        <div>
                            <img style={{height:window.innerWidth*0.08, width:window.innerWidth*0.07}} src={picture15} alt=""/>
                            <p style={{fontWeight:"bold"}}>抗压能力</p>
                        </div>
                        <div>
                            <img style={{height:window.innerWidth*0.08, width:window.innerWidth*0.07}} src={picture16} alt=""/>
                            <p style={{fontWeight:"bold"}}>猝死风险</p>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default LndicatorCollection;
