import picture1 from "./picture/个性化呼吸慢病管理创新单元.jpg"
import picture2 from "./picture/building.png"
import picture3 from "./picture/11212.jpg"
import picture4 from "./picture/runnanLogo.png"


function CompanyIntroduce() {

    return (
        <>
            <div id="company_introduce" style={{backgroundColor:"RoyalBlue", width:"100%", height:window.innerWidth*1.39}}>
                <div style={{padding:"1%", textAlign:"center", fontSize:window.innerWidth*0.032, fontWeight:"bold", fontFamily:"SimSun", color:"white", width:"90%", marginLeft:window.innerWidth*0.042, marginBottom:window.innerWidth*0.01}}>
                    <p style={{ marginBottom:window.innerWidth*0.005, marginTop:window.innerWidth*0.005}}>公司介绍</p>
                </div>
                <hr style={{width:window.innerWidth*0.473, height:window.innerWidth*0.00274, backgroundColor:"white", marginTop:window.innerWidth*0.001}}/>
                <div style={{width:"90%", backgroundColor:"white", marginLeft:window.innerWidth*0.05, marginTop:window.innerWidth*0.0315, marginRight:window.innerWidth*0.053}}>
                    <div>
                        <div style={{marginTop:window.innerWidth*0.032}}>
                          <p style={{padding:window.innerWidth*0.005, fontSize:window.innerWidth*0.0274, textAlign:"center", fontWeight:"bold", fontFamily:"SimSun", marginTop:window.innerWidth*-0.001, marginBottom:window.innerWidth*-0.01}}>中科心感（南京）医疗科技有限公司与中国科学院</p>

                            <p style={{fontSize:window.innerWidth*0.0274, textAlign:"center", fontWeight:"bold", fontFamily:"SimSun"}}>联合研究打造心脑血管、慢性病等智能化数字医疗检测系统</p>
                        </div>
                        <div style={{textAlign:"center", fontSize:window.innerWidth*0.0274, fontWeight:"bold", fontFamily:"SimSun", color:"RoyalBlue"}}>
                            <p>
                                科技医疗 守护生命 关爱之声 让生命绽放
                            </p>
                        </div>
                        <div style={{marginLeft:window.innerWidth*0.084}}>
                            <img style={{height:window.innerWidth*0.21, width:window.innerWidth*0.236}} src={picture1} alt=""/>
                            <img style={{height:window.innerWidth*0.25, width:window.innerWidth*0.236, marginLeft:window.innerWidth*0.021}} src={picture2} alt=""/>
                            <img style={{height:window.innerWidth*0.17, width:window.innerWidth*0.21, marginLeft:window.innerWidth*0.032, marginBottom:window.innerWidth*0.015}} src={picture3} alt=""/>
                        </div>
                        <div style={{textAlign:"center", fontSize:window.innerWidth*0.026, fontWeight:"bold", fontFamily:"SimSun", color:"RoyalBlue", marginTop:window.innerWidth*0.026}}>
                            <p>
                                中科心感（南京）医疗科技有限公司
                            </p>

                        </div>
                        <div style={{textAlign:"center", fontSize:window.innerWidth*0.026, fontWeight:"bold", fontFamily:"SimSun", color:"RoyalBlue", marginTop:window.innerWidth*0.026}}>
                            <p>
                                Zhongke Xinggan (Nanjing) Medical Technology Co.,Ltd
                            </p>
                        </div>
                        <hr style={{width:window.innerWidth*0.03, height:"4px", backgroundColor:"orange", marginLeft:window.innerWidth*0.447, marginTop:window.innerWidth*0.01}}/>
                        <div style={{fontSize:window.innerWidth*0.016, textAlign:"center", fontWeight:"bold", fontFamily:"SimSun", marginTop:"20px", marginBottom:"-20px", color:"MidnightBlue "}}>
                            中科心感（南京）医疗科技有限公司成立于2020年1月
                        </div>
                        <div style={{fontSize:window.innerWidth*0.016, textAlign:"center", fontWeight:"bold", fontFamily:"SimSun", marginTop:"50px", marginBottom:"-20px", color:"MidnightBlue "}}>
                            依托中科院传感技术国家重点实验室平台
                        </div>
                        <div style={{fontSize:window.innerWidth*0.016, textAlign:"center", fontWeight:"bold", fontFamily:"SimSun", marginTop:"50px", marginBottom:"-20px", color:"MidnightBlue "}}>
                            在新型医疗电子和医学人工智能领域的技术基础上
                        </div>
                        <div style={{fontSize:window.innerWidth*0.016, textAlign:"center", fontWeight:"bold", fontFamily:"SimSun", marginTop:"50px", marginBottom:"-20px", color:"MidnightBlue "}}>
                            利用最前沿的生物传感技术，自主研发无感式生命体征监测设备与个性化健康管理系统
                        </div>
                        <div style={{fontSize:window.innerWidth*0.016, textAlign:"center", fontWeight:"bold", fontFamily:"SimSun", marginTop:"50px", marginBottom:"-20px", color:"MidnightBlue "}}>
                            中科心感面向“以健康为中心”和“健康老龄化”的国家战略需求
                        </div>
                        <div style={{fontSize:window.innerWidth*0.016, textAlign:"center", fontWeight:"bold", fontFamily:"SimSun", marginTop:"50px", marginBottom:"-20px", color:"MidnightBlue "}}>
                            以国人医疗健康为中心，以实现生命全周期、医疗健康全过程的健康中国建设战略为已任
                        </div>
                        <div style={{fontSize:window.innerWidth*0.016, textAlign:"center", fontWeight:"bold", fontFamily:"SimSun", marginTop:"50px", marginBottom:"-20px", color:"MidnightBlue "}}>
                            专注于奖先进的医疗健康监测手段和医疗大数据分析技术进行市场化应用
                        </div>

                        <div style={{display:"flex"}}>
                            <div>
                                <img style={{marginLeft:window.innerWidth*-0.05, height:window.innerWidth*0.32, width:window.innerWidth*0.473, marginTop:window.innerWidth*0.042}} src={picture4} alt=""/>
                            </div>
                            <div>
                                <div style={{fontSize:window.innerWidth*0.016, textAlign:"center", fontWeight:"bold", fontFamily:"SimSun", marginTop:window.innerWidth*0.063, marginBottom:window.innerWidth*-0.01, marginLeft:window.innerWidth*-0.217}}>
                                    以练匠心、铸匠心、筑匠魂、塑匠人的传承为使命
                                </div>
                                <div style={{fontSize:window.innerWidth*0.016, textAlign:"center", fontWeight:"bold", fontFamily:"SimSun", marginTop:window.innerWidth*0.115, marginBottom:window.innerWidth*-0.01, marginLeft:window.innerWidth*-0.032}}>
                                    以求实、严谨精益求精的工作态度，以思维创新、理念创新、技术创新、经营创新为目标
                                </div>
                                <div style={{fontSize:window.innerWidth*0.016, textAlign:"center", fontWeight:"bold", fontFamily:"SimSun", marginTop:window.innerWidth*0.10, marginBottom:window.innerWidth*-0.01, marginLeft:window.innerWidth*-0.095}}>
                                    科学合理取舍，寻求互补多赢、实现可持续发展以诚信经营开创未来
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </>
    )
}


export default CompanyIntroduce;
