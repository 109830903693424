// import monitor from "./picture/稿定设计-5.png"
import heart from "./picture/heart.png"
import sleep from "./picture/睡眠.png"
import pressure from "./picture/产品压力测试.png"
import hrv from "./picture/分析.png"
// import {Component} from "react";

// class MonitorFunction extends Component {
//     render() {
//         // const backimg = {
//         //     // backgroundImage:`url(${background})`,
//         //     backgroundSize:"100% 100%",
//         //     // height:'10vh',
//         //     // scrollMarginTop:"140px",
//         //     display:"flex",
//         //     // marginTop:'50px',
//         //     // fontSize:'50px',
//         // }
//
//     }
//
// }

function MonitorFunction () {
    return (
        <>
            <div style={{display:"flex"}}>
                <div style={{ marginLeft:"21%", marginTop:"3.6%", marginBottom:"-1.6%"}}>
                    <div >
                        <div >
                            <b style={{fontFamily:"SimSun", fontSize:window.innerWidth*0.031, marginBottom:"0.5%", color:"RoyalBlue",}}>家用智能</b>
                        </div>
                        <hr style={{width:window.innerWidth*0.021, height:window.innerWidth*0.0021, backgroundColor:"Black", marginLeft:"0.5%", marginTop:"2.05%"}}/>
                        <div >
                            <b style={{fontFamily:"SimSun", fontSize:window.innerWidth*0.031, marginBottom:"10px", color:"RoyalBlue", marginTop:"0.01%"}}>健康管理</b>
                        </div>
                    </div>

                </div>
                <div style={{ marginRight:"-21%", display:"flex", marginTop:"0.01%"}}>
                    <table style={{marginTop:window.innerWidth*0.026, marginLeft:window.innerWidth*0.053, borderCollapse:"collapse", width:window.innerWidth*0.053, height:window.innerWidth*0.095}}>
                        <col/>
                        <tr>
                            <td style={{border:"5px solid RoyalBlue",padding:"10px"}}>
                                <p style={{fontSize:window.innerWidth*0.01, fontWeight:"bolder", fontFamily:"SimSun"}}>心率/呼吸</p>
                                <img style={{height:window.innerWidth*0.042, width:window.innerWidth*0.05, marginTop:"-7px", marginBottom:"-10px"}} src={heart} alt="heart"/>
                            </td>
                        </tr>

                    </table>
                    <table style={{marginTop:window.innerWidth*0.026, marginLeft:window.innerWidth*0.053, borderCollapse:"collapse", width:window.innerWidth*0.053, height:window.innerWidth*0.095}}>
                        <col/>
                        <tr>
                            <td style={{border:"5px solid RoyalBlue",padding:"10px"}}>
                                <p style={{fontSize:window.innerWidth*0.01, fontWeight:"bolder", fontFamily:"SimSun"}}>睡眠分析</p>
                                <img style={{height:window.innerWidth*0.042, width:window.innerWidth*0.047, marginTop:"5px", marginBottom:"-10px"}} src={sleep} alt="heart"/>
                            </td>
                        </tr>

                    </table>
                    <table style={{marginTop:window.innerWidth*0.026, marginLeft:window.innerWidth*0.053, borderCollapse:"collapse", width:window.innerWidth*0.053, height:window.innerWidth*0.095}}>
                        <col/>
                        <tr>
                            <td style={{border:"5px solid RoyalBlue",padding:"10px"}}>
                                <p style={{fontSize:window.innerWidth*0.01, fontWeight:"bolder", fontFamily:"SimSun"}}>压力分析</p>
                                <img style={{height:window.innerWidth*0.042, width:window.innerWidth*0.047, marginTop:"5px", marginBottom:"-10px"}} src={pressure} alt="heart"/>
                            </td>
                        </tr>

                    </table>
                    <table style={{marginTop:window.innerWidth*0.026, marginLeft:window.innerWidth*0.053, borderCollapse:"collapse", width:window.innerWidth*0.053, height:window.innerWidth*0.095}}>
                        <col/>
                        <tr>
                            <td style={{border:"5px solid RoyalBlue",padding:"10px"}}>
                                <p style={{fontSize:window.innerWidth*0.01, fontWeight:"bolder", fontFamily:"SimSun"}}>心率变异性分析</p>
                                <img style={{height:window.innerWidth*0.042, width:window.innerWidth*0.05, marginTop:"-7px", marginBottom:"-10px"}} src={hrv} alt="heart"/>
                            </td>
                        </tr>

                    </table>
                </div>


            </div>

        </>
    )
}


export default MonitorFunction;



