import picture1 from "./picture/642b0e74ef6f43571aae842e5635a72c.jpeg";
import picture2 from "./picture/稿定设计-11.png";
import picture3 from "./picture/稿定设计-12.png";
import {Col, Row} from "antd";

function ProductIntroduce2 () {
    return(
        <>
            <div style={{margin:"2.6% 0.5% 2.6% 3.5%", display:"flex", flexDirection:"row"}}>
              <Row>
                <Col span={8}>
                  <div style={{marginLeft:window.innerWidth*0.084, display:"flex", flexDirection:"column"}}>
                    <img style={{height:window.innerWidth*0.16, width:window.innerWidth*0.236}} src={picture1} alt=""/>
                    <b style={{fontFamily:"SimSun", fontSize:window.innerWidth*0.042, marginBottom:window.innerWidth*0.005,marginTop:window.innerWidth*0.0131,
                      color:"black", fontWeight:"bold", textAlign:"center", marginRight:window.innerWidth*-0.08, marginLeft:window.innerWidth*-0.06}}>无感式监测</b>
                    <p style={{fontSize:window.innerWidth*0.0131, backgroundColor:"orange",color:"white",textAlign:"left",
                      marginLeft:"0px", marginRight:"82.3%", marginTop:"0.5%",padding:"0px 0.5% 0px 0.26%"}}>舒适</p>
                    <p style={{width:window.innerWidth*0.236,fontSize:window.innerWidth*0.0131,fontFamily:"SimSun", fontWeight:"medium", marginTop:"0px", marginBottom:"1.6%", marginRight:"0px"}}>
                      通过无接触式的夜间睡眠监测心率、呼吸、睡眠、体动、心理压力、心率变异性、心率失常呼吸睡眠暂停等对身体进行全方位的健康指标分析</p>
                    <hr style={{width:window.innerWidth*0.021, height:window.innerWidth*0.0021, backgroundColor:"orange", marginLeft:"0.5%"}}/>
                  </div>
                </Col>
                <Col span={8}>
                  <div style={{marginLeft:"34.8%", display:"flex", flexDirection:"column"}}>
                    <img style={{height:window.innerWidth*0.16, width:window.innerWidth*0.236, marginLeft:"0px"}} src={picture2} alt=""/>
                    <b style={{fontFamily:"SimSun", fontSize:window.innerWidth*0.042, marginBottom:"0.5%",marginTop:window.innerWidth*0.0131,
                      color:"black", fontWeight:"bold", textAlign:"center", marginRight:window.innerWidth*-0.089, marginLeft:window.innerWidth*-0.06}}>可视化界面</b>
                    <p style={{fontSize:window.innerWidth*0.0131, backgroundColor:"orange",color:"white",textAlign:"left",marginLeft:"0px",
                      marginRight:window.innerWidth*0.141, marginTop:"0.5%",padding:"0px 0.5% 0px 0.26%"}}>方便</p>
                    <p style={{width:window.innerWidth*0.236,fontSize:window.innerWidth*0.0131,fontFamily:"SimSun", fontWeight:"medium", marginTop:"0px", marginBottom:"1.6%", marginRight:"0px"}}>
                      无需下载任何软件，微信扫一扫绑定设备、操作简单，每日自动生成健康报告，长期监测实现个性化健康管理</p>
                    <hr style={{width:window.innerWidth*0.021, height:window.innerWidth*0.0021, backgroundColor:"orange", marginLeft:"0.5%"}}/>
                  </div>
                </Col>
                <Col span={8}>
                  <div style={{marginLeft:"35.2%", display:"flex", flexDirection:"column"}}>
                    <img style={{height:window.innerWidth*0.16, width:window.innerWidth*0.236, marginLeft:"1.6%", marginBottom:"1.31%"}} src={picture3} alt=""/>
                    <b style={{fontFamily:"SimSun", fontSize:window.innerWidth*0.042, marginBottom:"0.25%",marginTop:window.innerWidth*0.0131,
                      color:"black", fontWeight:"bold", textAlign:"center", marginRight:window.innerWidth*-0.089, marginLeft:window.innerWidth*-0.06}}>医疗级精准</b>
                    <p style={{fontSize:window.innerWidth*0.0131, backgroundColor:"orange",color:"white",textAlign:"left",
                      marginLeft:"1.4%", marginRight:"80.3%", marginTop:"0.5%",padding:"0px 0.5% 0px 0.26%"}}>专业</p>
                    <p style={{width:window.innerWidth*0.236,fontSize:window.innerWidth*0.0131,fontFamily:"SimSun", fontWeight:"medium",
                      marginTop:"0px", marginBottom:"1.6%", marginRight:"0px", marginLeft:"1.31%"}}>
                      心感监测数据测试准确度高达99%，通过大量数据分析了解身体当下健康指标，可以通过日常连续性监测形成健康数据库</p>
                    <hr style={{width:window.innerWidth*0.021, height:window.innerWidth*0.0021, backgroundColor:"orange", marginLeft:"1.47%"}}/>
                  </div>
                </Col>
              </Row>
            </div>

        </>
    )
}

export default ProductIntroduce2
