import logo from "./picture/稿定设计-7.png"
import van from "./picture/稿定设计-4.png"
import customer_service from "./picture/稿定设计-3.png"
import {Col, Row} from "antd";


// function Title() {
//     return (
//         <>
//             <div id="index_page" style={{height: "110px", display:"flex", marginLeft:"400px"}}>
//                 <img style={{marginRight:"20px"}} src={logo} alt="logo"/>
//                 <h1 style={{fontSize:"40px"}}>中科心感（南京）医疗电子研究院有限公司 </h1>
//                 <div style={{display:"flex", flexDirection:"column"}}>
//                     <img  style={{marginLeft:"90px", height:"80px", width:"70"}} src={van} alt=""/>
//                     <p style={{fontWeight:"bold", marginLeft:"100px", marginTop:"0px"}}>快递包邮</p>
//                 </div>
//                 <div style={{display:"flex", flexDirection:"column"}}>
//                     <img  style={{marginLeft:"50px", height:"80px", width:"70"}} src={customer_service} alt=""/>
//                     <p style={{fontWeight:"bold", marginLeft:"65px", marginTop:"0px"}}>咨询客服</p>
//                 </div>
//             </div>
//             <div style={{ height: "40px", margiLeft:"510px", display:"flex", backgroundColor:"DodgerBlue"}}>
//                 <a href="#index_page"
//                     style={{type:"text", marginLeft:"420px", textAlign:"center", marginTop:"10px", fontSize:"20px", color:"white"}}
//                 >首页</a>
//                 <a href="#monitor_introduce" style={{type:"text", marginLeft:"50px", textAlign:"center", marginTop:"10px", fontSize:"20px", color:"white"}}>镇店之宝</a>
//                 <a href="#company_introduce" style={{type:"text", marginLeft:"50px", textAlign:"center", marginTop:"10px", fontSize:"20px", color:"white"}}>公司介绍</a>
//                 <a href="#product_introduce" style={{type:"text", marginLeft:"50px",textAlign:"center", marginTop:"10px", fontSize:"20px", color:"white"}}>产品介绍</a>
//                 <a href="#phone" style={{type:"text", marginLeft:"50px",textAlign:"center", marginTop:"10px", fontSize:"20px", color:"white"}}>联系方式</a>
//             </div>
//         </>
//     )
// }


// function Title() {
//   return (
//     <>
//       <div id="index_page" style={{ display:"flex", marginLeft:"20%"}}>
//         <div>
//           <img style={{marginRight:"1.3%",width:window.innerWidth*0.06}} src={logo} alt="logo"/>
//         </div>
//         <h1 style={{fontSize:"250%"}}>中科心感（南京）医疗电子研究院有限公司 </h1>
//         <div style={{display:"flex", flexDirection:"column"}}>
//           <img  style={{marginLeft:"50.7%", height:"53%", width:window.innerWidth*0.046}} src={van} alt=""/>
//           <p style={{fontWeight:"bold", marginLeft:window.innerWidth*0.053}}>快递包邮</p>
//         </div>
//         <div style={{display:"flex", flexDirection:"column"}}>
//           <img  style={{marginLeft:"33%", height:"53%", width:window.innerWidth*0.046}} src={customer_service} alt=""/>
//           <p style={{fontWeight:"bold", marginLeft:window.innerWidth*0.03}}>咨询客服</p>
//         </div>
//       </div>
//       <div style={{ height: "26%", margiLeft:"34%", display:"flex", backgroundColor:"DodgerBlue"}}>
//         <a href="#index_page"
//            style={{type:"text", marginLeft:"21%", textAlign:"center", marginTop:"0.66%", fontSize:"20px", color:"white"}}
//         >首页</a>
//         <a href="#monitor_introduce" style={{type:"text", marginLeft:"3.2%", textAlign:"center", marginTop:"0.66%", fontSize:"20px", color:"white"}}>镇店之宝</a>
//         <a href="#company_introduce" style={{type:"text", marginLeft:"3.2%", textAlign:"center", marginTop:"0.66%", fontSize:"20px", color:"white"}}>公司介绍</a>
//         <a href="#product_introduce" style={{type:"text", marginLeft:"3.2%",textAlign:"center", marginTop:"0.66%", fontSize:"20px", color:"white"}}>产品介绍</a>
//         <a href="#phone" style={{type:"text", marginLeft:"3.2%",textAlign:"center", marginTop:"0.66%", fontSize:"20px", color:"white"}}>联系方式</a>
//       </div>
//     </>
//   )
// }
//
// export default Title;


function Title() {
  return (
    <>
      <div style={{width:"100%", textAlign:"center", display:"flex", justifyContent:"center",  position: "fixed", top: 0, backgroundColor:"white", zIndex:"1000"}}>
        <Row >
          <Col>
            <div >
              <img style={{marginRight:"1.3%",width:window.innerWidth*0.06, marginTop:window.innerWidth*0.005}} src={logo} alt="logo"/>
            </div>
          </Col>
          <Col>
            <div >
              <img style={{marginRight:"1.3%",width:window.innerWidth*0.01, opacity:"0"}} src={logo} alt="logo"/>
              <h1 style={{fontSize:"30px"}}>中科心感（南京）医疗电子研究院有限公司 </h1>
            </div>
          </Col>
          <Col  >
            <div >
              <img  style={{marginLeft:"30.7%", height:"53%", width:window.innerWidth*0.046, marginTop:window.innerWidth*0.005}} src={van} alt=""/>
              <p style={{fontWeight:"bold", marginLeft:window.innerWidth*0.03}}>快递包邮</p>
            </div>

          </Col>
          <Col >
            <div >
              <img  style={{marginLeft:"33%", height:"53%", width:window.innerWidth*0.046, marginTop:window.innerWidth*0.005}} src={customer_service} alt=""/>
              <p style={{fontWeight:"bold", marginLeft:window.innerWidth*0.03}}>咨询客服</p>
            </div>
          </Col>
        </Row>
      </div>


      <div style={{ width:"100%", textAlign:"center", margiLeft:"34%", display:"flex", justifyContent:"center" , backgroundColor:"DodgerBlue",  position: "fixed", top:window.innerWidth*0.073, zIndex:"1000"}}>
        <Row gutter={25} >
          <Col span={4}  >
            <div>
              <a href="#monitor_introduce"
                 style={{type:"text",  textAlign:"center", marginTop:"0.66%", fontSize:window.innerWidth*0.011, color:"white", marginLeft:"-725%"}}
              >首页</a>
            </div>
          </Col>
          <Col span={5} >
            <div>
              <a href="#monitor_introduce" style={{type:"text", marginLeft:"-525%", textAlign:"center", marginTop:"0.66%", fontSize:window.innerWidth*0.011, color:"white"}}>镇店之宝</a>
            </div>
          </Col>
          <Col span={5}>
            <div>
              <a href="#company_introduce" style={{type:"text", marginLeft:"-450%", textAlign:"center", marginTop:"0.66%", fontSize:window.innerWidth*0.011, color:"white"}}>公司介绍</a>

            </div>
          </Col>
          <Col span={5} >
            <div>
              <a href="#product_introduce" style={{type:"text", marginLeft:"-350%",textAlign:"center", marginTop:"0.66%", fontSize:window.innerWidth*0.011, color:"white"}}>产品介绍</a>
            </div>
          </Col>
          <Col span={5} >
            <div>
              <a href="#phone" style={{type:"text", marginLeft:"-250%",textAlign:"center", marginTop:"0.66%", fontSize:window.innerWidth*0.011, color:"white"}}>联系方式</a>
            </div>
          </Col>
        </Row>

      </div>

    </>
  )
}

export default Title;
