import picture1 from "./picture/稿定设计-2.png"
import picture2 from "./picture/稿定设计-16.png"
import picture3 from "./picture/稿定设计-13.png"
import picture4 from "./picture/稿定设计-15.png"


function EndUser() {

    return (
        <>
            <div style={{backgroundColor:"RoyalBlue ", width:"100%", height:window.innerWidth*0.87}}>
                {/*<div style={{padding:"20px", textAlign:"center", fontSize:"60px", fontWeight:"bold", fontFamily:"SimSun", color:"white", width:"90%", marginLeft:"80px", marginBottom:"20px"}}>*/}
                {/*    <p style={{ marginBottom:"10px", marginTop:"10px"}}>终端用户使用说明</p>*/}
                {/*</div>*/}
              <div style={{padding:"1%", textAlign:"center", fontSize:window.innerWidth*0.03115, fontWeight:"bold", fontFamily:"SimSun", color:"white", width:"90%", marginLeft:"4.2%", marginBottom:"1%"}}>
                <p style={{ marginBottom:"0.5%", marginTop:"0.5%"}}>终端用户使用说明</p>
              </div>
                <hr style={{width:window.innerWidth*0.47, height:window.innerWidth*0.0018, backgroundColor:"white", marginTop:"1%"}}/>
                <div style={{width:"70%", backgroundColor:"white", marginLeft:"13.1%", marginTop:"3.2%", display:"flex", marginBottom:"2.6%"}}>
                    <div style={{marginTop:"5%", marginLeft:"5.6%"}}>
                        <div style={{marginLeft:"4.7%", marginRight:"-5%", display:"flex", flexDirection:"column"}}>
                            <img style={{height:window.innerWidth*0.131, width:window.innerWidth*0.236}} src={picture1} alt=""/>
                            <b style={{fontFamily:"SimSun", fontSize:window.innerWidth*0.026, marginBottom:"0.5%", marginTop:"10.2%",
                              color:"RoyalBlue", fontWeight:"bold", marginRight:"5%", marginLeft:"7.9%"}}>养老机构</b>
                            <p style={{width:window.innerWidth*0.236,fontSize:window.innerWidth*0.0158,fontFamily:"SimSun",
                              fontWeight:"medium", marginTop:"1%", marginBottom:"1.58%", marginRight:"0px", marginLeft:"-2.26%"}}>提供健康管理服务及安全预警，预防老年人在睡眠中出现严重突发性状况，并为每位老年人建立个人健康数据，方便管理。</p>
                        </div>
                        <div style={{marginLeft:"4.7%", marginRight:"-5.3%", display:"flex", flexDirection:"column", marginTop:"10.6%"}}>
                            <img style={{height:window.innerWidth*0.131, width:window.innerWidth*0.236}} src={picture2} alt=""/>
                            <b style={{fontFamily:"SimSun", fontSize:window.innerWidth*0.026, marginBottom:"0.5%",marginTop:"10.21%",
                              color:"RoyalBlue", fontWeight:"bold", marginRight:"0px", marginLeft:"8.1%"}}>亚健康人群</b>
                            <p style={{width:window.innerWidth*0.236,fontSize:window.innerWidth*0.0158,fontFamily:"SimSun",
                              fontWeight:"medium", marginTop:"0px", marginBottom:"1.6%", marginRight:"0px", marginLeft:"-2.2%"}}>将该设备制作成坐垫可以在日常监测白领人群的生理参数，通过对日常生理参数的分析，可获得一些隐性病症，从而提前做出预防。</p>
                        </div>
                    </div>
                    <hr style={{width:"1px", height:window.innerWidth*0.68, backgroundColor:"black", marginTop:"1%", marginLeft:"10%"}}/>
                    <div style={{marginTop:window.innerWidth*0.0293, marginLeft:"0px", marginRight:"9.1%"}}>
                        <div style={{marginTop:"5.3%", marginLeft:"0px"}}>
                            <div style={{marginLeft:"0px", marginRight:"4.47%", display:"flex", flexDirection:"column"}}>
                                <b style={{fontFamily:"SimSun",margin:"3.2% 5% 0.5% -5.4%", fontSize:window.innerWidth*0.026,  color:"RoyalBlue", fontWeight:"bold"}}>医疗机构</b>
                                <p style={{width:window.innerWidth*0.236,fontSize:window.innerWidth*0.0158,fontFamily:"SimSun",
                                  margin:"0px 0px 1.58% -5.3%", fontWeight:"medium"}}>对心血管疾病等慢性病提供无感式监控措施，减少护士和医生的时间成本，并为慢性病患者提供长期个人健康数据。</p>
                                <img style={{height:window.innerWidth*0.131, width:window.innerWidth*0.236, marginLeft:"-5.2%", marginTop:"10.2%"}} src={picture3} alt=""/>
                            </div>
                            {/*, paddingLeft:"210px"*/}
                            <div style={{marginLeft:"0px", marginRight:"-5.3%", display:"flex", flexDirection:"column", marginTop:"2.6%"}}>
                                <b style={{fontFamily:"SimSun", fontSize:window.innerWidth*0.026, marginBottom:"0.5%",marginTop:"10.1%",
                                  color:"RoyalBlue", fontWeight:"bold", marginRight:"0px", marginLeft:"-5.6%"}}>老人家庭看护</b>
                                <p style={{width:window.innerWidth*0.236,fontSize:window.innerWidth*0.0158,fontFamily:"SimSun",
                                  fontWeight:"medium", marginTop:"0px", marginBottom:"1.58%", marginRight:"0px", marginLeft:"-5.5%"}}>满足儿女对老人健康的关心，使子女更好的了解和管理老人的健康状态，同时也实现心血管等疾病的预警。</p>
                                <img style={{height:window.innerWidth*0.131, width:window.innerWidth*0.236, marginLeft:"-5.5%", marginTop:"10.1%", marginBottom:"3%"}} src={picture4} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}


export default EndUser;
