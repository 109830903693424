import picture1 from "./picture/picture1.jpeg"
import picture2 from "./picture/picture2.jpeg"
import picture3 from "./picture/picture3.jpg"
import picture4 from "./picture/picture4.jpeg"
import picture5 from "./picture/picture5.jpg"
import picture from "./picture/微信图片_20230621171615.png"
import {Col, Row} from "antd";


function Publicize() {
    return (
        <>
            <div style={{marginBottom:"0.021%", marginLeft:"4.9%"}}>
              {/*<img src={picture} alt=""/>*/}
                <div style={{display:"flex", margin:"-0.013% -0.016% -0.021% 35.5%"}}>
                    <p style={{fontSize:window.innerWidth*0.021, fontFamily:"SimSun", fontWeight:"900"}}>中科心感</p>
                    <p style={{fontSize:window.innerWidth*0.021, fontFamily:"SimSun",fontWeight:"900", color:"DodgerBlue", marginLeft:"1.5%"}}>关爱健康</p>
                </div>
              <hr style={{width:"31.5%", height:"1px", backgroundColor:"Black", marginLeft:"28.6%", marginTop:"1%"}}/>

              <div>
                  <img style={{marginTop:"2%", marginLeft:"14.2%", marginBottom:"2%", width:window.innerWidth*0.561, height:window.innerWidth*0.274}} src={picture} alt=""/>
                </div>
                {/*<div style={{ marginLeft:"18%", marginBottom:"-18.6%", marginTop:"3.15%"}}>*/}
                {/*    <div style={{marginTop:"2.6%", marginBottom:"2.6%", marginLeft:"10%"}}>*/}
                {/*        <img  style={{height:window.innerWidth*0.176, width:window.innerWidth*0.11}} src={picture1} alt=""/>*/}
                {/*        <img  style={{height:window.innerWidth*0.074, width:window.innerWidth*0.11, marginBottom:window.innerWidth*0.102}} src={picture2} alt=""/>*/}
                {/*        <img  style={{height:window.innerWidth*0.074, width:window.innerWidth*0.11, marginBottom:window.innerWidth*0.102}} src={picture3} alt=""/>*/}
                {/*    </div>*/}
                {/*    /!*<div style={{margin:"3.68% 47.3% 3.68% 11.6%", backgroundColor:"orange"}}>*!/*/}
                {/*    <div style={{marginTop:window.innerWidth*0.0368,marginRight:window.innerWidth*0.473, marginBottom:window.innerWidth*0.0368, marginLeft:window.innerWidth*0.1,backgroundColor:"orange"}}>*/}
                {/*      <Row >*/}
                {/*        <Col span={12}>*/}
                {/*          <p style={{textAlign:"center", fontSize:window.innerWidth*0.005, height:window.innerWidth*0.0142, width:window.innerWidth*0.11, marginTop:window.innerWidth*(-0.143),*/}
                {/*            marginRight:window.innerWidth*0.1993, marginBottom:window.innerWidth*0.118,marginLeft:window.innerWidth*0.198, backgroundColor:"orange"}}>放置枕下 操作便捷</p>*/}
                {/*        </Col>*/}
                {/*        <Col span={12}>*/}
                {/*          <p style={{textAlign:"center",fontSize:"2px", height:window.innerWidth*0.0142, width:window.innerWidth*0.11, marginTop:window.innerWidth*-0.143,*/}
                {/*            marginRight:window.innerWidth*0.01278, marginBottom:window.innerWidth*-0.1029,marginLeft:window.innerWidth*-0.0155, backgroundColor:"orange"}}>夜间风险 即可知晓</p>*/}
                {/*        </Col>*/}
                {/*      </Row>*/}
                {/*    </div>*/}

                {/*    <div style={{marginTop:window.innerWidth*0.1, marginBottom:window.innerWidth*0.026, marginLeft:window.innerWidth*0.192}}>*/}
                {/*        <Row>*/}
                {/*          <Col span={6}>*/}
                {/*            <img  style={{height:window.innerWidth*0.074, width:window.innerWidth*0.11, marginBottom:window.innerWidth*0.1698, marginTop:window.innerWidth*-0.257, marginLeft:window.innerWidth*-0.00473}} src={picture4} alt=""/>*/}

                {/*          </Col>*/}
                {/*          <Col span={6}>*/}
                {/*            <img  style={{height:window.innerWidth*0.074, width:window.innerWidth*0.11, marginBottom:window.innerWidth*0.1698, marginTop:window.innerWidth*-0.257, marginLeft:window.innerWidth*-0.04}} src={picture5} alt=""/>*/}

                {/*          </Col>*/}
                {/*        </Row>*/}
                {/*        /!*<img  style={{height:"140px", width:"200px", marginBottom:"180px"}} src={picture4} alt=""/>*!/*/}
                {/*        /!*<img  style={{height:"140px", width:"200px", marginBottom:"180px"}} src={picture5} alt=""/>*!/*/}
                {/*    </div>*/}
                {/*    /!*<div style={{margin:"70px 900px 70px 200px", backgroundColor:"orange"}}>*!/*/}
                {/*    <div style={{margin:"3.687% 47.3% 3.685% 10%", backgroundColor:"orange"}}>*/}
                {/*      <Row>*/}
                {/*        <Col>*/}
                {/*          <p style={{textAlign:"center",fontSize:window.innerWidth*0.005, height:window.innerWidth*0.0142, width:window.innerWidth*0.119, marginTop:window.innerWidth*(-0.204),*/}
                {/*            marginRight:window.innerWidth*0.111, marginBottom:window.innerWidth*0.125,marginLeft:window.innerWidth*0.110,backgroundColor:"orange"}}>实时心率 远程查看</p>*/}
                {/*        </Col>*/}
                {/*        <Col>*/}
                {/*          <p style={{textAlign:"center",fontSize:window.innerWidth*0.005, height:window.innerWidth*0.0142, width:window.innerWidth*0.119, marginTop:window.innerWidth*(-0.204),*/}
                {/*            marginRight:window.innerWidth*0.111, marginBottom:window.innerWidth*0.125,marginLeft:window.innerWidth*0.22,backgroundColor:"orange"}}>睡眠报告 微信推送</p>*/}
                {/*        </Col>*/}
                {/*      </Row>*/}
                {/*        /!*<p style={{height:"28px", width:"143px",  margin:"-254px 244px 226px 240px",backgroundColor:"orange"}}>实时心率 远程查看</p>*!/*/}
                {/*      /!*<p style={{height:window.innerWidth*0.015, width:window.innerWidth*0.119,  margin:"-13.4% 12.8% 11.9% 12.6%",backgroundColor:"orange"}}>实时心率 远程查看</p>*!/*/}

                {/*      /!*<p style={{height:"27px", width:"170px",  margin:"-254px 0px 226px 430px", backgroundColor:"orange"}}>睡眠报告 微信推送</p>*!/*/}

                {/*    </div>*/}
                {/*</div>*/}
            </div>

        </>
    )
}


export default Publicize;
