import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Title from "./title"
import Monitor from "./monitor_introduce";
import MonitorFunction from "./main_function";
import Publicize from "./publicize";
import CompanyIntroduce from "./company_introduce";
import ProductIntroduce from "./product_introduce";
import ProductIntroduce2 from "./product_introduce2";
import LndicatorCollection from "./lndicator_collection";
import Algorithm from "./algorithm";
import EndUser from "./end_user";
import EndPage from "./end_page";

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Title />
      <Monitor />
      <MonitorFunction />
      <Publicize />
      <CompanyIntroduce />
      <ProductIntroduce />
      <ProductIntroduce2 />
      <LndicatorCollection />
      <Algorithm />
      <EndUser />
      <EndPage />

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
