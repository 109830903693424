import picture1 from "./picture/稿定设计-10.png";
import picture2 from "./picture/稿定设计-14.png";
import picture3 from "./picture/稿定设计-13 (2).png";


function Algorithm () {

    return(
        <>
            <div style={{margin:"2.6% 0.5% 2.6% 0.5%"}}>
                <div style={{display:"flex", flexDirection:"row", textAlign:"center", marginLeft:"31%"}}>
                    <p style={{textAlign:"center", fontSize:window.innerWidth*0.021, color:"red", fontFamily:"SimSun", marginLeft:"0.5%", fontWeight:"bold"}}>人工智能精准医疗</p>
                    <p style={{textAlign:"center", fontSize:window.innerWidth*0.021, fontFamily:"SimSun", marginLeft:"3%", fontWeight:"bold"}}>产品数据分析</p>
                </div>
                <hr style={{width:window.innerWidth*0.47, height:"1px", backgroundColor:"black", marginTop:"1%"}}/>
                <div style={{margin:"2.6% 0.5% 2.6% 5.5%", display:"flex", flexDirection:"row"}}>
                    <div style={{marginLeft:"8.4%", display:"flex", flexDirection:"column"}}>
                        <img style={{height:window.innerWidth*0.157, width:window.innerWidth*0.236}} src={picture1} alt=""/>
                        <b style={{fontFamily:"SimSun", fontSize:window.innerWidth*0.0158, marginBottom:"0.5%",marginTop:"5%",
                          color:"black", fontWeight:"bold", textAlign:"center", marginRight:"5%", marginLeft:"-0.5%"}}>连续生命体征采集</b>
                        <p style={{width:window.innerWidth*0.236,fontSize:window.innerWidth*0.013,fontFamily:"SimSun", fontWeight:"medium", marginTop:"5.5%",
                          marginBottom:"1.58%", marginRight:"0px"}}>精准提取逐拍心率，并对影响逐拍心动周期提取精度的主要因素进行分析，最终得到用户的逐拍心律周期序列的时域、频域、非线性域的HRV指标等数据。</p>
                    </div>
                    <div style={{marginLeft:"1.58%", display:"flex", flexDirection:"column"}}>
                        <img style={{height:window.innerWidth*0.157, width:window.innerWidth*0.236, marginLeft:"0px"}} src={picture2} alt=""/>
                        <b style={{fontFamily:"SimSun", fontSize:window.innerWidth*0.0158, marginBottom:"0.5%",marginTop:"5%",
                          color:"black", fontWeight:"bold", textAlign:"center", marginRight:"3.9%", marginLeft:"-0.5%"}}>健康数据分析算法</b>
                        <p style={{width:window.innerWidth*0.236,fontSize:window.innerWidth*0.013,fontFamily:"SimSun", fontWeight:"medium", marginTop:"5.5%",
                          marginBottom:"1.58%", marginRight:"0px"}}>通过逐拍心跳提取算法可以精准的提取使用者的逐拍心跳进行更精准的心率变异性(HRV) 分析，进而进行更深入的健康分析、疾病预警等功能。</p>
                    </div>
                    <div style={{marginLeft:"0px", display:"flex", flexDirection:"column"}}>
                        <img style={{height:window.innerWidth*0.157, width:window.innerWidth*0.236, marginLeft:"4.58%", marginBottom:"1.31%"}} src={picture3} alt=""/>
                        <b style={{fontFamily:"SimSun", fontSize:window.innerWidth*0.0158, marginBottom:"0.5%",marginTop:"4.5%",
                          color:"black", fontWeight:"bold", textAlign:"center", marginRight:"0.84%", marginLeft:"-0.5%"}}>提供健康方案</b>
                        <p style={{width:window.innerWidth*0.236,fontSize:window.innerWidth*0.013,fontFamily:"SimSun", fontWeight:"medium", marginTop:"4.3%",
                          marginBottom:"1.58%", marginRight:"0px", marginLeft:"1.3%"}}>通过大数据结合数据挖掘得出的医学模型，发现用户心率变异特征、呼吸暂停事件，形成健康状况模型，提供科学可靠的健康状况分析及健康危急事件预警，对高危状态提前识别，对危急事件及时预警。</p>
                    </div>
                </div>
                </div>
        </>
    )
}

export default Algorithm
